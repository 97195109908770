








import ComponentForgotPassword from '@/components/ForgotPassword.vue';
import Vue from 'vue';
export default Vue.extend({
  'name': 'ForgotPassword',
  'components': {
    ComponentForgotPassword
  }
});
